import type { TRPCClientErrorLike } from "@trpc/client";
import type {
  RouterInput,
  RouterOutput,
  AppRouter,
} from "@mono/api/src/server/trpc/router";

export type APIInputs = RouterInput;
export type APIOutputs = RouterOutput;
export type APIError = TRPCClientErrorLike<AppRouter>;

export const useTrpcClient = () => {
  const { $trpcClient } = useNuxtApp();

  return {
    trpcClient: $trpcClient,
  };
};
