import { z } from "zod";
import { locales, type Locale } from "@mono/constants/lib/locale";
import { generalReceiptEnum } from "@mono/constants/lib/generalReceipt";
export { generalReceiptEnum, type Locale };

// main validation for dates
// strict iso date format, without time
export const dateStringSchema = z
  .string()
  .refine(
    (val) => {
      return (
        z
          .string()
          .regex(
            /^2[0-1]\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])(T00:00:00.000Z)?$/
          )
          .safeParse(val).success && z.coerce.date().safeParse(val).success
      );
    },
    {
      params: { i18n: "custom.invalid_date_string" },
    }
  )
  .transform(
    // transform to iso date format with zero time T00:00:00.000Z
    (val) => {
      if (val.includes("T")) {
        return val;
      }

      return val.concat("T00:00:00.000Z");
    }
  );

export const arrayIdsWhereSchema = z
  .array(z.string().uuid())
  .transform((val) => (val.length ? val : undefined))
  .optional();

export const nonEmptyStringSchema = z.string().trim().min(1);
export const emptyStringSchema = z.string().trim().min(0).max(0);

export const stringNumbersSchema = z.string().trim();
export const stringNumbersSchemaRefinement = (
  arg: string,
  ctx: z.RefinementCtx
) => {
  if (new RegExp(/^(|\d+)$/).test(arg) === false) {
    ctx.addIssue({
      code: "custom",
      params: { i18n: "custom.invalid_number_string" },
    });
  }
};

export const passwordSchema = z.string().min(6).max(128);
export const passwordSchemaRefinement = (arg: string, ctx: z.RefinementCtx) => {
  if (new RegExp(/^[^'";\s]*$/).test(arg) === false) {
    ctx.addIssue({
      code: "custom",
      params: { i18n: "custom.invalid_alpha" },
    });
  }
};

export const taxNumberSchema = stringNumbersSchema
  .min(1)
  .length(15)
  .superRefine(stringNumbersSchemaRefinement)
  .or(emptyStringSchema);

export const phoneCCSchemaRefinement = (arg: string, ctx: z.RefinementCtx) => {
  if (new RegExp(/^\+[0-9]{1,4}$/).test(arg) === false) {
    ctx.addIssue({
      code: "custom",
      params: { i18n: "custom.invalid_phone_cc" },
    });
  }
};

export const phoneCCSchema = z.string();

export const bilingualTextSchema = nonEmptyStringSchema
  .regex(/^[^><}{\[\]]*$/)
  .max(1000);

export const translationSchema = z
  .object({
    en: bilingualTextSchema.or(emptyStringSchema).optional(),
    ar: bilingualTextSchema.or(emptyStringSchema).optional(),
  })
  .optional();
export type TranslationSchema = z.infer<typeof translationSchema>;

export const localeSchema = z
  .string()
  .refine((val) => locales.includes(val as Locale), {
    message: `Must be one of ${locales.join(", ")}`,
    params: {
      i18n: {
        key: "errors.invalid_literal",
        expected: { literal: locales.join(", ") },
      },
    },
  });

export const printNotesSchema = z.array(
  z.object({
    title: z.string(),
    text: z.string(),
  })
);
export type PrintNotesSchema = z.infer<typeof printNotesSchema>;

export const transactionSource = [
  "manualJournals",
  "generalReceipts",
  "purchasingInvoices",
  "purchasingReceipts",
  "inventoryConsumptions",
  "inventoryTransfers",
  "inventoryStocktakes",
  "salesInvoices",
  "salesReceipts",
  "fixedAssets",
  "fixedAssetTransactions",
  "creditNotes",
  "debitNotes",
  "customersOpeningBalances",
  "vendorsOpeningBalances",
] as const;

export const roundingNumberSchema = z
  .number()
  .transform((val) => Math.round(val));
